import React, {
	createContext,
	ReactElement,
	ReactNode,
	useEffect,
	useState,
} from 'react'
import Helmet from 'react-helmet'
import classNames from 'classnames'

enum Media {
	Light = '(prefers-color-scheme: light)',
	Dark = '(prefers-color-scheme: dark)',
}

const ThemeContext = createContext({
	theme: '',
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	toggleTheme: () => {},
})

const Theme = ({ children }: { children: ReactNode }): ReactElement | null => {
	const [isLoading, setIsLoading] = useState(true)
	const [theme, setTheme] = useState(
		typeof window !== 'undefined' && window.matchMedia(Media.Dark).matches
			? 'dark'
			: 'light'
	)

	const scheduleDarkMode = (): string => {
		console.log(
			'You specified no preference for a color scheme or your browser does not support it. I schedule dark mode during night time.'
		)
		const currentHour = new Date().getHours()
		if (currentHour < 8 || currentHour >= 20) {
			return 'dark'
		}

		return 'light'
	}

	useEffect(() => {
		const isDarkMode = window.matchMedia(Media.Dark).matches
		const isLightMode = window.matchMedia(Media.Light).matches
		const savedTheme = localStorage.getItem('theme')

		if (!isDarkMode && !isLightMode && !savedTheme) {
			setTheme(scheduleDarkMode())
		} else if (savedTheme) {
			setTheme(savedTheme)
		}

		setIsLoading(false)

		const handleDarkModeChange = (e: MediaQueryListEvent): false | void =>
			setTheme(e.matches ? 'dark' : 'light')

		window
			.matchMedia(Media.Dark)
			.addEventListener('change', handleDarkModeChange)

		return (): void => {
			window
				.matchMedia(Media.Dark)
				.removeEventListener('change', handleDarkModeChange)
		}
	}, [])

	const toggleTheme = (): void => {
		const nextTheme = theme === 'dark' ? 'light' : 'dark'
		localStorage.setItem('theme', nextTheme)
		setTheme(nextTheme)
	}

	if (isLoading) return null

	return (
		<ThemeContext.Provider value={{ theme, toggleTheme }}>
			<Helmet
				bodyAttributes={{ class: classNames({ darkMode: theme === 'dark' }) }}
			/>
			{children}
		</ThemeContext.Provider>
	)
}

export { Theme as ThemeProvider, ThemeContext as ThemeConsumer }
