import React, { ReactElement, ReactNode } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { HeaderLight } from './header'
import { ThemeProvider } from '../context/Theme'

interface Props {
	header?: ReactElement
	children?: ReactNode
	minimal?: boolean
}

const Layout = ({
	header = <HeaderLight />,
	children,
	minimal,
}: Props): ReactElement => {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						author
					}
				}
			}
		`
	)
	const {
		siteMetadata: { author },
	} = site

	return (
		<ThemeProvider>
			{header}
			<main {...(minimal && { className: 'minimal' })}>{children}</main>
			<footer {...(minimal && { className: 'minimal' })}>
				<span>
					© {new Date().getFullYear()} {author}
				</span>
			</footer>
		</ThemeProvider>
	)
}

export default Layout
