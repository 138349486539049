import React, { ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faTwitter,
	faGithub,
	faKeybase,
	faStackOverflow,
} from '@fortawesome/free-brands-svg-icons'
import { faRss } from '@fortawesome/free-solid-svg-icons'

interface Props {
	networks: {
		twitter: string
		github: string
		stackoverflow: string
		keybase: string
	}
	postsEnabled: boolean
}

const SocialNetworks = ({ networks, postsEnabled }: Props): ReactElement => (
	<ul className="social-networks">
		<li className="twitter">
			<a
				href={networks.twitter}
				target="_blank"
				rel="noopener noreferrer"
				aria-label="Twitter Profile"
			>
				<FontAwesomeIcon
					icon={faTwitter}
					style={{
						width: 'auto',
						height: '1.5em',
						fontSize: '1.3333333333em',
					}}
				/>
			</a>
		</li>
		<li className="github">
			<a
				href={networks.github}
				target="_blank"
				rel="noopener noreferrer"
				aria-label="Github Profile"
			>
				<FontAwesomeIcon
					icon={faGithub}
					style={{
						width: 'auto',
						height: '1.5em',
						fontSize: '1.3333333333em',
					}}
				/>
			</a>
		</li>
		<li className="keybase">
			<a
				href={networks.keybase}
				target="_blank"
				rel="noopener noreferrer"
				aria-label="Keybase Profile"
			>
				<FontAwesomeIcon
					icon={faKeybase}
					style={{
						width: 'auto',
						height: '1.5em',
						fontSize: '1.3333333333em',
					}}
				/>
			</a>
		</li>
		<li className="stackoverflow">
			<a
				href={networks.stackoverflow}
				target="_blank"
				rel="noopener noreferrer"
				aria-label="StackOverflow Profile"
			>
				<FontAwesomeIcon
					icon={faStackOverflow}
					style={{
						width: 'auto',
						height: '1.5em',
						fontSize: '1.3333333333em',
					}}
				/>
			</a>
		</li>
		{postsEnabled ? (
			<li className="rss">
				<a href="/rss.xml" aria-label="RSS">
					<FontAwesomeIcon
						icon={faRss}
						style={{
							width: 'auto',
							height: '1.5em',
							fontSize: '1.3333333333em',
						}}
					/>
				</a>
			</li>
		) : null}
	</ul>
)

export default SocialNetworks
