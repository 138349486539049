/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactElement } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

interface Props {
	title?: string
	description?: string
	lang?: string
	meta?: []
}

const SEO = ({ title, description, lang, meta = [] }: Props): ReactElement => {
	const data = useStaticQuery(
		graphql`
			query SEOQuery {
				site {
					siteMetadata {
						title
						description
						author
						siteUrl
						seo {
							facebookAppId
							twitterHandle
						}
					}
				}
				ogAvatar: file(absolutePath: { regex: "/me.png/" }) {
					childImageSharp {
						gatsbyImageData(
							width: 1200
							height: 1200
							placeholder: BLURRED
							layout: FIXED
						)
					}
				}
				twitterAvatar: file(absolutePath: { regex: "/me.png/" }) {
					childImageSharp {
						gatsbyImageData(
							width: 450
							height: 450
							placeholder: BLURRED
							layout: FIXED
						)
					}
				}
			}
		`
	)

	const {
		site: {
			siteMetadata: {
				title: _title,
				description: _description = description,
				author,
				siteUrl,
				seo: { facebookAppId, twitterHandle },
			},
		},
		ogAvatar: {
			childImageSharp: { gatsbyImageData: ogAvatarSrc },
		},
		twitterAvatar: {
			childImageSharp: { gatsbyImageData: twitterAvatarSrc },
		},
	} = data

	const titleTemplate = title ? `${title} | ${_title}` : `${_title}`

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={titleTemplate}
			titleTemplate={titleTemplate}
			meta={[
				{
					name: `description`,
					content: _description,
				},
				{
					property: `og:title`,
					content: titleTemplate,
				},
				{
					property: `og:description`,
					content: _description,
				},
				{
					property: `og:image`,
					content: `${siteUrl}${ogAvatarSrc}`,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:url`,
					content: siteUrl,
				},
				{
					property: `fb:app_id`,
					content: facebookAppId,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: author,
				},
				{
					name: `twitter:title`,
					content: titleTemplate,
				},
				{
					name: `twitter:description`,
					content: _description,
				},
				{
					name: `twitter:creator`,
					content: twitterHandle,
				},
				{
					name: `twitter:image`,
					content: `${siteUrl}${twitterAvatarSrc}`,
				},
			].concat(meta)}
		/>
	)
}

SEO.defaultProps = {
	title: ``,
	description: ``,
	lang: `en`,
	meta: [],
}

SEO.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
