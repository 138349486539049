import React, { ReactElement, useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb as faLightbulbSolid } from '@fortawesome/free-solid-svg-icons'
import { faLightbulb as faLightbulbRegular } from '@fortawesome/free-regular-svg-icons'
import About from './about'
import SocialNetworks from './socialNetworks'
import { ThemeConsumer } from '../context/Theme'

export const HeaderLarge = (): ReactElement => {
	const { site, avatar, headerBackground } = useStaticQuery(
		graphql`
			{
				site {
					siteMetadata {
						author
						authorJobTitle
						authorJobCompany
						companyUrl
						socialNetworks {
							twitter
							github
							keybase
							stackoverflow
						}
						postsEnabled
					}
				}
				headerBackground: file(
					absolutePath: { regex: "/header-background.png/" }
				) {
					childImageSharp {
						gatsbyImageData(width: 1200, height: 350, layout: FIXED)
					}
				}
				avatar: file(absolutePath: { regex: "/me.png/" }) {
					childImageSharp {
						gatsbyImageData(width: 200, height: 200, layout: FIXED)
					}
				}
			}
		`
	)
	const {
		siteMetadata: {
			author,
			authorJobTitle,
			authorJobCompany,
			companyUrl,
			socialNetworks: networks,
			postsEnabled,
		},
	} = site

	return (
		<header className="intro">
			<ToogleDarkMode />
			<figure id="header-avatar">
				<GatsbyImage
					image={avatar.childImageSharp.gatsbyImageData}
					alt="TheGiantBeast Avatar"
				/>
			</figure>
			<section>
				<h1>{author}</h1>
				<h2>
					{authorJobTitle} @ <a href={companyUrl}>{authorJobCompany}</a>
				</h2>
				<SocialNetworks networks={networks} postsEnabled={postsEnabled} />
			</section>
			<figure id="header-background">
				<GatsbyImage
					image={headerBackground.childImageSharp.gatsbyImageData}
					alt="Lisbon Landscape at Night"
				/>
				<small>
					Photo by{' '}
					<a href="https://unsplash.com/photos/sTiUQ7oT5qc">
						Fulvio Ambrosanio
					</a>{' '}
					on <a href="https://unsplash.com/">Unsplash</a>
				</small>
			</figure>
			<About />
		</header>
	)
}

export const HeaderLight = (): ReactElement => (
	<header className="slim">
		<BackLink />
		<ToogleDarkMode />
	</header>
)

const BackLink = (): ReactElement => (
	<Link
		style={{
			boxShadow: `none`,
			textDecoration: `none`,
			color: `inherit`,
		}}
		to={`/`}
	>
		&larr; Back to Home
	</Link>
)

const ToogleDarkMode = (): ReactElement => {
	const { theme, toggleTheme } = useContext(ThemeConsumer)
	return (
		<button
			className="toggle-darkmode"
			onClick={toggleTheme}
			aria-label="Toggle Dark Mode"
		>
			<FontAwesomeIcon
				icon={theme === 'dark' ? faLightbulbSolid : faLightbulbRegular}
			/>
		</button>
	)
}
