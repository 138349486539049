import React, { ReactElement } from 'react'

const About = (): ReactElement => (
	<section id="about">
		<p>
			<strong>What&apos;s in my bag?</strong>
		</p>
		<p>
			Years of relevant experience combined with cutting edge technologies, from
			full-stack developer to system administrator. An hard worker self-taught
			and a problem solver, that challenges unmaintainable code and complex
			architectures every single day. Excellent communication skills and
			experience with both distributed and local teams. Motivated by complex
			challenges, that solve real problems and constantly trying to provide the
			simplest solution.
		</p>
		<p>
			Lead, contributed and/or implemented several projects on a wide range of
			technologies such as a replay mechanism for HTML5 games, CLI for managing
			games, web apps and tasks automation.
		</p>
	</section>
)

export default About
